#navigation-popup {
  width: 100%;
}
#navigation-popup .MuiBackdrop-invisible + div {
  width: 100% !important;
  position: unset !important;
}
.yes {
  white-space: nowrap;
}
.no {
  white-space: nowrap;
}
.containerForButtons {
  display: flex;
  justify-content: center;
}
#navigation-popup .MuiPaper-root.MuiPaper-elevation,#cancel-request-popup .MuiPaper-root.MuiPaper-elevation {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}
#cancel-request-popup .MuiPaper-root.MuiPaper-elevation{
    height: fit-content;
}
#cancel-request-popup {
  width: 100%;
}
@media only screen and (max-width: 1024px) and (min-width: 768px) {
  #navigation-popup .MuiBackdrop-invisible + div {
    width: 50% !important;
    position: fixed !important;
    right: 0;
  }
  #navigation-popup .MuiPaper-root.MuiPaper-elevation,#cancel-request-popup .MuiPaper-root.MuiPaper-elevation {
    top: 50% !important;
    left: 75% !important;
    transform: translate(-50%, -50%) !important;
  }
}
@media only screen and (min-width: 1025px) {
  #navigation-popup .MuiBackdrop-invisible + div {
    width: 35% !important;
    float: right;
  }
  #navigation-popup .MuiPaper-root.MuiPaper-elevation,#cancel-request-popup .MuiPaper-root.MuiPaper-elevation {
    top: 50% !important;
    left: 82.3% !important;
    transform: translate(-50%, -50%) !important;
  }
}
