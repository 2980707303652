.removeText {
  float: right;
  font-family: LeasePlan;
  font-size: 10px;
  cursor: pointer;
  color: #f06400;
  text-decoration: none solid rgb(240, 100, 0);
  margin: 2px;
}

.undo {
  font-family: LeasePlan;
  font-size: 10px;
  color: #f06400;
  cursor: pointer;
  text-decoration: none solid rgb(240, 100, 0);
  text-decoration: underline;
  font-weight: 100;
}

.update {
  font-family: LeasePlan;
  font-size: 10px;
  color: #f06400;
  text-decoration: none solid rgb(240, 100, 0);
  font-weight: 100;
  margin-left: 5px;
}

.removeText:hover {
  text-decoration: underline;
  cursor: pointer;
}

.vehicleData {
  font-family: LeasePlan;
  font-size: 12px;
  color: #004a5d;
  text-decoration: none solid rgb(0, 74, 93);
  margin: 20px;
  display: flex;
  flex-direction: column;
  letter-spacing: 0px !important;
}
.vehicleData1 {
  font-family: LeasePlan;
  font-size: 12px;
  color: #004a5d;
  letter-spacing: 0px !important;
  text-decoration: none solid rgb(0, 74, 93);
  margin: 20px;
  display: flex;
  flex-direction: column;
}

.selectedEntity {
  background-color: #f7f8fb;
  border-radius: 2px;
  fill: #f7f8fb;
  border: 2px solid #eeeeee;
  stroke-width: 1;
}
.removespacing {
  letter-spacing: 0px !important;
}
