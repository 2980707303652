table {
  text-align: left;
  font-size: 12px;
}

td {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: LeasePlan;
  font-size: 12px;
  padding: 5px;
  color: #545656;
  text-decoration: none solid rgb(0, 74, 93);
}

tr {
  padding: 10px;
  cursor: pointer;
}

th {
  background: white;
  position: sticky;
  font-size: 10px;
  color: #989a9a;
  top: 0;
  padding: 10px;
  /* Don't forget this, required for the stickiness */
}

tr .arrow {
  margin-right: 0.5rem;
  opacity: 0;
  transition: opacity 0.2s ease-out;
  cursor: pointer;
  transition-delay: 0.2s;
}

tr:hover .arrow {
  opacity: 1;
  background-color: #f6f8fb;
  color: #004a5d;
  padding-top: 7px;
}
tr:hover {
  opacity: 1;
  background-color: #f6f8fb;
  text-decoration: none solid rgb(0, 74, 93);
}
