.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 1500ms infinite;
  animation: ellipsis steps(4, end) 1500ms infinite;
  content: "\2026";
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1em;
  }
}

.newRequest{
  position: absolute;
  margin-top: -14.313rem;
   right: 1.875rem;
}
@media only screen and (max-width: 992px)  and (min-width: 600px) {
  .newRequest{
    position: absolute;
    margin-top: -21.875rem;
    right: 1.875rem;
  }
}
@media only screen and (max-width: 600px) {
  .newRequest{
    position: absolute;
    margin-top: -17.5rem;
    right: 1.875rem;
  }
}