.sessionScreen{
    width:98%;
    margin: auto;
}
@media only screen and (max-width: 1024px) and (min-width: 768px) {
    .sessionScreen{
        width:50%;
        margin: auto;
    }
}

@media only screen and (min-width: 1025px) {
    .sessionScreen{
        width:35%;
        margin: auto;
    }
}